<template>
  <div class="hello">

    <!--SECCION LOGIN / NUEVA CUENTA-->
    <div v-if="!userLogin || newUser" class="login-container">
        <div class="icon">
            <!-- Coloca aquí tu icono o imagen de la web -->
            <img src="../assets/logo.png" alt="Icono de la web" style="width: 150px; margin-top: 10vh; margin-left: 15px">
        </div>
        <div class="login-form" v-if="!newUser">
            <h3>Iniciar Sesión</h3>
            <br>
            <form autocomplete="on" @submit.prevent="login">
                <div>
                  <b-form-input style="height: 47px;" type="text" placeholder="Email" v-model="userInput" name="email"></b-form-input>
                </div>
                <br>
                <div>
                  <b-form-input style="height: 47px;" type="password" placeholder="Contraseña" v-model="passInput" name="password" @keyup.enter="login"></b-form-input>
                </div>
                <br>
                <div class="form-group">
                  <b-button style="background-color: #B9A16B" @click="login">Iniciar sesión</b-button>
                </div>
                <p v-if="errorLogin != ''" style="color: red">{{errorLogin}}</p>
                <div class="form-group" style="margin-top: 40px">
                  <p class="text-cuenta-nueva" @click="newUser = true">Crear cuenta nueva</p>
                </div>
                <!--<p style="position: absolute; top: 92%;left: 25%; color: #B9A16B">He olvidado mi contraseña</p>-->
            </form>
        </div>
        <div class="login-form" v-else>
            <h3>Nuevo usuario</h3>
            <br>
            <form>
                <div>
                  <b-form-input style="height: 47px;" type="email" placeholder="Email" v-model="newEmail"></b-form-input>
                </div>
                <br>
                <div>
                  <b-form-input style="height: 47px;" type="password" placeholder="Contraseña" v-model="newPass"></b-form-input>
                </div>
                <br>
                <div>
                  <b-form-input style="height: 47px;" type="password" placeholder="Repetir Contraseña" v-model="newRePass"></b-form-input>
                </div>
                <br>
                <p v-if="errorNewUser != ''" style="color: red">{{errorNewUser}}</p>
                <div class="form-group">
                  <b-button style="background-color: #B9A16B" @click="addNewUser()">Crear cuenta</b-button>
                </div>
                <div class="form-group" style="margin-top: 40px">
                  <p class="text-cuenta-nueva" @click="newUser = false">Volver</p>
                </div>
                
            </form>
        </div>
    </div>
    <!--SECCION PRINCIPAL-->
    <div v-else>
      <!--MENU SELECCION CUADRO-->
      <div class="row" v-if="itemSelect.ledValor == -1">
        <div class="col-12"  style="text-align: right;">
          <span style="margin-right: 5px" v-if="items.length == 0"><b>Añadir un nuevo dispositivo</b></span>
          <b-button @click="modalAdd = true" class="rounded-circle button-add"><i class="fa-solid fa-plus"></i></b-button>
        </div>      
      </div>
      <br v-if="itemSelect.ledValor == -1">
      <div v-if="itemSelect.ledValor == -1" class="row justify-content-md-center" style="padding-left: 20px; padding-right: 5px">
        <div class="col-md-6 col-lg-3" v-for="item in items" :key="item.id">
          <div class="card mb-3 border-0 card-round shadow" @click="dispositivoClick(item)">
            <div class="row g-0" >
              <div class="col-3">
                <img :src="'/img/' + item.image" class="img-fluid rounded-start marco-principal" alt="Imagen">   
              </div>
              <div class="col-7 d-flex align-items-center">
                <div class="card-body p-0">
                  <h5 class="card-title" :class="{ 'dispositivo-on': item.encendido }">{{ item.nombre }} <span v-if="item.temp != undefined" style="text-decoration: underline;">{{item.temp}}°C</span></h5>
                </div>
              </div>
              <div class="col-2">
                  <div class="card-body p-0 d-flex align-items-center justify-content-center" style="height: 100%">
                    <img v-if="!item.encendido" src="../assets/iconos/calefaccion_off.png" style="max-width: 50px" class="img-fluid rounded-start marco-principal" alt="Imagen">     
                    <img v-if="item.encendido" src="../assets/iconos/calefaccion_on.png" style="max-width: 50px" class="img-fluid rounded-start marco-principal" alt="Imagen">    
                  </div>
               </div>
            </div>             
          </div>
        </div>
       
      </div>
      <!--CONTROL CUADRO-->
      <div v-else>
        <div class="row">
          <div class="div-back" style="text-align: left;">
            <i class="fa-solid fa-chevron-left btn-back" style=" margin-top:5px; font-size: 22px;" @click="itemSelect = {ledValor: -1}"></i>
          </div>
          <div class="div-nombre">
            <p style="font-size: 23px;"><b>{{ itemSelect.nombre }}</b></p>
          </div>
          <div class="div-options" style="text-align: right;">
            <i class="fa-solid fa-wrench btn-option" style=" margin-top:5px; font-size: 22px;" @click="openModalDispositivo"></i>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <img :src="'/img/' + itemSelect.image" class="img-fluid rounded-start marco-principal img-control" alt="Imagen">
          </div>       
        </div>
        <!-- Tres botones en la parte inferior del nuevo div -->
        <div class="row div-potencia" v-if="itemSelect.tipo == 'marco'">
            <div class="col-12" >
              <b-button-group style="width: 80%">
                <b-button class="btn-group btn1-potencia d-flex justify-content-center align-items-center" :class="{ 'btn-activo': itemSelect.potencia === 1 }" @click="publishPotencia(1)"><i v-if="itemSelect.potencia == 1" class="fa-solid fa-check" style=" margin-right: 5px"></i> Bajo</b-button>
                <b-button class="btn-group d-flex justify-content-center align-items-center" :class="{ 'btn-activo': itemSelect.potencia === 2 }" @click="publishPotencia(2)"><i v-if="itemSelect.potencia == 2" class="fa-solid fa-check" style=" margin-right: 5px"></i>Medio</b-button>
                <b-button class="btn-group btn3-potencia d-flex justify-content-center align-items-center" :class="{ 'btn-activo': itemSelect.potencia === 3 }" @click="publishPotencia(3)"><i v-if="itemSelect.potencia == 3" class="fa-solid fa-check" style="margin-right: 5px"></i>Alto</b-button>
              </b-button-group>
            </div>
          </div>
          
        <div class="row" style="margin-top: 20px">
          <div class="col-12">
            <b-button style="border-radius: 50%; background-color: white; box-shadow: 9px 7px 46px -16px rgba(0,0,0,0.75);" :class="{'btn-encendido': itemSelect.encendido}" @click="publishCalefaccion()"> 
              <img v-if="!itemSelect.encendido" src="../assets/iconos/calefaccion_off.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">     
              <img v-if="itemSelect.encendido" src="../assets/iconos/calefaccion_on.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">     
            </b-button>
          </div>
        </div>

        <div class="row" style="margin-top: 20px" v-if="itemSelect.tipo == 'espejo'">
          <div class="col-6">
            <b-button class="boton-led"
              :style="`background-image: linear-gradient(transparent 0%, transparent ${100-itemSelect.ledValor}%, rgb(185, 161, 107) ${100-itemSelect.ledValor}%, rgb(185, 161, 107) 100%);`"  
              @click="publishLed()"> 
              <img src="../assets/iconos/bombilla_off.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">     
              <!--<img v-if="!itemSelect.led" src="../assets/iconos/bombilla_off.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">     
              <img v-if="itemSelect.led" src="../assets/iconos/bombilla_on.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">    
              :style="`background-image: linear-gradient(transparent 0%, transparent ${itemSelect.ledValor}%, rgb(185, 161, 107) ${itemSelect.ledValor}%, rgb(185, 161, 107) 100%);`"  --> 
            </b-button>          
            <!--<input style="margin-top:10px;" type="range" v-model="itemSelect.ledValor" min="0" max="100" step="1" />-->
            <input style="margin-top:10px;" type="range" class="custom-slider" v-model="itemSelect.ledValor" min="0" max="100" step="1" />

           
          </div>
          <div class="col-6">
            <b-button style="border-radius: 50%; background-color: white; box-shadow: 9px 7px 46px -16px rgba(0,0,0,0.75);" :class="{'btn-encendido': itemSelect.vaho}" @click="publishVaho()"> 
              <img v-if="!itemSelect.vaho" src="../assets/iconos/antivaho_off.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">     
              <img v-if="itemSelect.vaho" src="../assets/iconos/antivaho_on.png" style="max-width: 100px;" class="img-fluid rounded-start" alt="Imagen">
            </b-button>
          </div>

            <!--<b-button v-b-modal.modal-1>Launch demo modal</b-button>-->

        </div>
      </div>

      <!--PIE-->
      <div class="footer">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 text-center icon-home" @click="homeClick()">
              <i class="fas fa-house footer-icon"></i>
            </div>
            <!--<div class="col-6 text-center icon-user">
              <i class="fas fa-user footer-icon fa-2x"></i>
            </div>-->
          </div>
        </div>
      </div>

    </div>

    <b-modal v-model="modalAdd" id="modal-add" title="BootstrapVue" hide-header hide-footer centered >
      <p style="text-align: center;">Agregar nuevo dispositivo</p>
       <form>
          <div>
            <b-form-input style="height: 47px;" type="text" placeholder="Numero de serie" v-model.lazy="numSerieInput"
    @input="convertirAMayusculas"></b-form-input>
          </div>
          <br>
          <div>
            <b-form-input style="height: 47px;" type="text" placeholder="Nombre" v-model="nombreCuadroInput" ></b-form-input>
          </div>
          <br>
          <div class="row g-0 justify-content-center form-group">
            <b-button style="background-color: #B9A16B" @click="addDispositivo">Agregar dispositivo</b-button>
          </div>
          <p v-if="errorNuevoCuadro != ''" style="color: red; text-align: center">{{errorNuevoCuadro}}</p>
      </form>
    </b-modal>

     <b-modal v-model="modalOption" id="modal-add" title="BootstrapVue" hide-header hide-footer centered >
      <p style="text-align: center;">Opciones cuadro</p>
       <form>
        <!--CAMBIO DE NOMBRE-->
          <div>
            Nombre
            <b-form-input style="height: 47px;" type="text" placeholder="Nombre dispositivo" v-model="nuevoNombreInput" ></b-form-input>
            <p v-if="errorNuevoNombre != ''" style="color: red; text-align: center">{{errorNuevoNombre}}</p>
          </div>
          <div class="row g-0 justify-content-center form-group">
            <b-button style="background-color: #B9A16B" @click="updateNombreDispositivo">Guardar Nombre</b-button>
          </div>
          <!--ENVIAR INVITACION-->
          <div v-if="itemSelect.admin == 1">
            Enviar acceso a:
            <b-form-input style="height: 47px;" type="email" placeholder="Email" v-model="emailInvitadoInput" ></b-form-input>
            <p v-if="errorInvitacion != ''" style="color: red; text-align: center">{{errorInvitacion}}</p>
          </div>
          <div class="row g-0 justify-content-center form-group" v-if="itemSelect.admin == 1">
            <b-button style="background-color: #B9A16B" @click="invitarDispositivo">Enviar invitacion</b-button>
          </div>
          <!--BORRAR INVITADOS-->
          <!--ELIMINAR CUADRO-->
          
          <br>
          <div class="row g-0 justify-content-center form-group">
            <b-button style="background-color: #FF4560" @click="delDispositivo">Borrar dispositivo</b-button>
          </div>
      </form>
    </b-modal>

   </div>
</template>

<script>
import axios from "axios";

//import mqtt from 'mqtt'
/*
- B9A16B
- EDEAD3
- 82888C
*/
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      //apiUrl: "http://localhost:3050",
      //apiUrl: "https://radiant-deco-api-0dd0600969d5.herokuapp.com",
      apiUrl: "https://radiantdeco-api-2088347ca74e.herokuapp.com",
      configHeader: {
        headers: {
          "Authorization ": this.getCurrentUserToken(),
        },
      },
      userToken: "",
      modalAdd: false,
      modalOption: false,
      nuevoNombreInput: '',
      emailInvitadoInput: '',
      userInput: "",
      passInput: "",
      newEmail: "",
      newPass: "",
      newRePass: "",
      errorLogin: "",
      errorNewUser: "",
      errorInvitacion: "",
      numSerieInput: "",
      nombreCuadroInput: "",
      errorNuevoCuadro: "",
      errorNuevoNombre: "",
       isOn: false, // Estado de la luz (encendida o apagada)
      brightness: 100, // Nivel de brillo (0-100)
      userLogin: false,
      newUser: false,
      items:[],
      items2: [
        {
          id: 1,
          name: "Wi-Fi Radiant Circular",
          image: "circular.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url:'4C7525E87560',
          led: false,
          vaho: false,
          ledValor: 0
        },
         {
          id: 8,
          name: "Wi-Fi Radiant Baño",
          image: "espejo.png",
          type: 'espejo',
          encendido: false,
          potencia: 1,
          url:'4C7525E8F250',
          led: false,
          vaho: false,
          ledValor: 0
        },
        {
          id: 2,
          name: "Wi-Fi Radiant Paris",
          image: "Paris.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: '24DCC3A19D50',
          led: false,
          vaho: false,
          ledValor: 0
        },
        {
          id: 3,
          name: "Wi-Fi Radiant Elefante",
          image: "Elefante.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: 'B0A732316F70',
          led: false,
          vaho: false,
          ledValor: 0
        },        {
          id: 4,
          name: "Wi-Fi Radiant Mapa",
          image: "Tierra.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: '24DCC39F6CA8',
          led: false,
          vaho: false,
          ledValor: 0
        },        {
          id: 5,
          name: "Wi-Fi Radiant Abstracto",
          image: "Abstracto.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: 'B0A732324AE4',
          led: false,
          vaho: false,
          ledValor: 0
        },        {
          id: 6,
          name: "Wi-Fi Radiant Bosque",
          image: "Bosque.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: 'B0A732323718',
          led: false,
          vaho: false,
          ledValor: 0
        },        {
          id: 7,
          name: "Wi-Fi Radiant Infantil",
          image: "Osos.png",
          type: 'marco',
          encendido: false,
          potencia: 1,
          url: '24DCC3A17F94',
          led: false,
          vaho: false,
          ledValor: 0
        },
        
        // Agrega más elementos aquí
      ],
      cargandoIndividual: false,
      itemSelect:{
          
          ledValor: -1,
        },
      timer: null,
    };
  },
  mounted()
  {
     //const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  
      // Agrega estilos para deshabilitar el scroll
      /*document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollBarWidth}px`;*/

       const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith("token=")) {
            this.userLogin = true;
            this.loadDispositivos();
          }
        }
      //this.newUser = true;       
  },
  created() {
    window.addEventListener('focus', this.handleFocus);
  },
  destroyed() {
    window.removeEventListener('focus', this.handleFocus);
  },
  methods: {
    handleFocus() {
      this.loadDispositivos(true);
    },
    login(){

       let dataUser = {email: this.userInput, pass: this.passInput};

      axios
        .post(this.apiUrl+"/auth/login",dataUser)
        .then(response => {
            return response.data;
        })
        .then(res => {

          const diasExpiracion = 90; 
          const fechaExpiracion = new Date();
          fechaExpiracion.setTime(fechaExpiracion.getTime() + (diasExpiracion * 24 * 60 * 60 * 1000));
          const cookieValor = `${res.token}; expires=${fechaExpiracion.toUTCString()}`;
          document.cookie = `token=${cookieValor}`;

          this.userLogin = true;
          this.configHeader = {
              headers: {
                "Authorization ": 'Bearer ' + res.token,
              },
            };

          this.loadDispositivos();

        }).catch(error =>{

          this.errorLogin = '';

          if (error.response && error.response.data.msg) {
              this.errorLogin = error.response.data.msg;
          } else {
              this.errorLogin = "Hay un problema con el servidor.";
          }

        });


      /*if(this.userInput == "Radiant" && this.passInput == "Javi")
      {
        const diasExpiracion = 30; 
        const fechaExpiracion = new Date();
        fechaExpiracion.setTime(fechaExpiracion.getTime() + (diasExpiracion * 24 * 60 * 60 * 1000));
        const cookieValor = `radiant; expires=${fechaExpiracion.toUTCString()}`;
        document.cookie = `nombreUsuario=${cookieValor}`;
        this.userLogin = true;
      }
      else{
        this.errorLogin = "Usuario o contraseña incorrecta";
        this.passInput = '';
        this.userInput = '';
      }*/
    },
    publishPotencia(data) {
      //console.log(data);
      this.itemSelect.potencia = data;
      this.$forceUpdate();
      this.$mqtt.publish('radiant/'+this.itemSelect.url, 'potencia:'+this.itemSelect.potencia);

      let dataSend = {estado: 'potencia', id: this.itemSelect.id_dispositivo, valor: data};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });
    },
    publishCalefaccion() {
      //console.log(data);
      this.itemSelect.encendido = !this.itemSelect.encendido;
      if(this.itemSelect.encendido)
        this.itemSelect.vaho = false;
      this.$forceUpdate();
      this.$mqtt.publish('radiant/'+this.itemSelect.url, 'calefaccion:'+this.itemSelect.encendido)        
      
      let dataSend = {estado: 'encendido', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.encendido};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });


        dataSend = {estado: 'vaho', id: this.itemSelect.id_dispositivo, valor: false};
          axios
            .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
            .then(response => {
                return response.data;
            })
            .then(() => {
            }).catch(() =>{
            });
    },
    publishLed() {
      this.cargandoIndividual = true; 
      this.itemSelect.led = !this.itemSelect.led;
      if(this.itemSelect.led)
        this.itemSelect.ledValor = 100;
      else
        this.itemSelect.ledValor = 0;

      this.$forceUpdate();

      this.$mqtt.publish('radiant/'+this.itemSelect.url, 'led:'+this.itemSelect.led)      

      let dataSend = {estado: 'led', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.led};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });

        
      dataSend = {estado: 'ledValor', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.ledValor};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });
    },
    publishLedDimable() {

      
      if(this.itemSelect.ledValor != null){
        if(this.itemSelect.ledValor == 0)
          this.itemSelect.led = false;
        else
          this.itemSelect.led = true;

        this.$mqtt.publish('radiant/'+this.itemSelect.url, 'dim:'+(this.itemSelect.ledValor * 220) / 100) 

        let dataSend = {estado: 'led', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.led};

       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });

        dataSend = {estado: 'ledValor', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.ledValor};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });
      }
    },
    publishVaho() {
      //console.log(data);
      this.itemSelect.vaho = !this.itemSelect.vaho;
      if(this.itemSelect.vaho)
        this.itemSelect.encendido = false;
      this.$mqtt.publish('radiant/'+this.itemSelect.url, 'vaho:'+this.itemSelect.vaho)    
      
      let dataSend = {estado: 'vaho', id: this.itemSelect.id_dispositivo, valor: this.itemSelect.vaho};
       axios
        .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {
        }).catch(() =>{
        });

        dataSend = {estado: 'encendido', id: this.itemSelect.id_dispositivo, valor: false};
          axios
            .put(this.apiUrl+"/dispositivo",dataSend, this.configHeader)
            .then(response => {
                return response.data;
            })
            .then(() => {
            }).catch(() =>{
            });
        },
    getCurrentUserToken(){

      let tokenCode = "";

      const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.startsWith("token=")) {
            tokenCode = cookie.slice("token=".length);

          }
        }

      return "Bearer " + tokenCode;
    },
    clickNewUser(){
      this.newUser = true;
    },
    convertirAMayusculas() {
      this.numSerieInput = this.numSerieInput.toUpperCase();
    },
    addNewUser(){

      if(!this.esEmailValido(this.newEmail))
      {
        this.errorNewUser = "El email no es valido.";
        return;
      }
      else if(this.newPass != this.newRePass)
      {
        this.errorNewUser = "Las contraseñas no coinciden.";
        return;
      }
      else if (!this.esContrasenaSegura(this.newPass))
      {
        return;
      }

      let dataUser = {email: this.newEmail, pass: this.newPass};

      axios
        .post(this.apiUrl+"/user",dataUser)
        .then(response => {
            return response.data;
        })
        .then(res => {

          const diasExpiracion = 90; 
          const fechaExpiracion = new Date();
          fechaExpiracion.setTime(fechaExpiracion.getTime() + (diasExpiracion * 24 * 60 * 60 * 1000));
          const cookieValor = `${res.token}; expires=${fechaExpiracion.toUTCString()}`;
          document.cookie = `token=${cookieValor}`;

          this.userLogin = true;
          this.newUser = false;
          this.configHeader = {
              headers: {
                "Authorization ": this.getCurrentUserToken(),
              },
            };

          this.loadDispositivos();
          this.$forceUpdate();

        }).catch(error =>{

          if (error.response && error.response.data) {
              this.errorNewUser = error.response.data;
          } else {
              this.errorNewUser = "Hay un problema con el servidor.";
          }
          
        });

    },
    esEmailValido(email) {
      const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return expresionRegular.test(email);
    },
    esContrasenaSegura(contrasena) {
      // Verificar la longitud mínima
      if (contrasena.length < 8) {
          this.errorNewUser = "La contraseña tiene que tener al menos 8 caracteres.";
          return false;
      }

      // Verificar al menos una letra mayúscula
      if (!/[A-Z]/.test(contrasena)) {
          this.errorNewUser = "La contraseña tiene que al menos una letra mayúscula.";
          return false;
      }

      // Verificar al menos una letra minúscula
      if (!/[a-z]/.test(contrasena)) {
          this.errorNewUser = "La contraseña tiene que al menos una letra minúscula.";
          return false;
      }

      // Verificar al menos un número
      if (!/\d/.test(contrasena)) {
          this.errorNewUser = "La contraseña tiene que al menos un numero.";
          return false;
      }

      // La contraseña pasa todas las verificaciones, es considerada segura
      return true;
    },
    addDispositivo(){
      let data = {sn: this.numSerieInput, nombre: this.nombreCuadroInput};

      /*if(this.numSerieInput.length != 10)
      {
        this.errorNuevoCuadro = "El numero de serie no es valido.";
        return;
      }*/

      //console.log(data);

      axios
        .post(this.apiUrl+"/dispositivo/emparejar",data, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {

          this.modalAdd = false;

          this.loadDispositivos();

        }).catch(error =>{

          if (error.response && error.response.data) {
              this.errorNuevoCuadro = error.response.data;
          } else {
              this.errorNuevoCuadro = "Hay un problema con el servidor.";
          }
          
        });

      //numSerieInput
      //nombreCuadroInput

    },
    updateNombreDispositivo(){

      let data = {nombre: this.nuevoNombreInput.trim(), id: this.itemSelect.id_dispositivo};

      if(data.nombre.length == 0 || data.nombre.length == " " )
      {
        this.errorNuevoNombre = "El nombre no puede estar vacio.";
        return;
      }

       axios
        .put(this.apiUrl+"/dispositivo/nombre",data, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {

          this.modalOption = false;
          this.itemSelect.nombre = data.nombre;

        }).catch(error =>{

          if (error.response && error.response.data) {
              this.errorNuevoCuadro = error.response.data;
          } else {
              this.errorNuevoCuadro = "Hay un problema con el servidor.";
          }
          
        });

    },
    dispositivoClick(dato){
      this.cargandoIndividual = true;
      this.itemSelect = dato;    
      //this.cargandoIndividual = false;  

      //console.log(dato);

       /*axios
        .get(this.apiUrl+"/dispositivo/"+dato.id_dispositivo, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(res => {
          
          this.itemSelect.encendido = res.encendido;
          this.itemSelect.led = res.led;
          this.itemSelect.ledValor = res.ledValor;
          if(res.potencia == 0)
            this.itemSelect.potencia = 1;
          else
            this.itemSelect.potencia = res.potencia;
          
          this.itemSelect.vaho = res.vaho;
          if(res.temp != undefined)
            this.itemSelect.temp = res.temp;

          this.cargandoIndividual = false;
          
        }).catch(() =>{
            this.cargandoIndividual = false;
        });*/


    },
    invitarDispositivo(){

      let data = {nombre: this.itemSelect.nombre, id: this.itemSelect.id_dispositivo, email: this.emailInvitadoInput};

      if(!this.esEmailValido(data.email))
      {
        this.errorInvitacion = "El email no es valido";
        return;
      }

       axios
        .post(this.apiUrl+"/dispositivo/invitar",data, this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(() => {

          this.emailInvitadoInput = "";

          this.modalOption = false;

        }).catch(error =>{


          if (error.response && error.response.data) {
              this.errorInvitacion = error.response.data;
          } else {
              this.errorInvitacion = "Hay un problema con el servidor.";
          }
          
        });

    },
    homeClick(){
      this.itemSelect = {ledValor: -1}

      this.loadDispositivos(true);
    },
    openModalDispositivo(){
      this.nuevoNombreInput = this.itemSelect.nombre;
      this.modalOption = true;
    },
    delDispositivo(){

      let mensajeBorrar = "¿Estas seguro que quieres borrar el dispositivo de tu cuenta? Tambien se borrara de todos los usuarios que tengan el control.";

      if(this.itemSelect.admin == 0){
        mensajeBorrar = "¿Estas seguro que quieres borrar el dispositivo de tu cuenta?";
      }

      this.$bvModal.msgBoxConfirm(mensajeBorrar, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'confirmacion',
          hideHeaderClose: false,
          centered: true,
          cancelClass: 'ml-2',
        })
          .then(value => {
            if(value)
            {
              let data = {id: this.itemSelect.id_dispositivo, admin: this.itemSelect.admin};

              axios
                .post(this.apiUrl+"/dispositivo/delete",data, this.configHeader)
                .then(response => {
                    return response.data;
                })
                .then(() => {

                  this.modalOption = false;

                  this.items = this.items.filter(item => item.id_dispositivo !== this.itemSelect.id_dispositivo);
                  this.itemSelect = {ledValor: -1};

                }).catch(() =>{
                });
              
            }
            //dispositivoDel
          })
          .catch(() => {
            
            // An error occurred
          })

    },
    loadDispositivos(cache = false){

      if(!cache)
        this.items = [];

       axios
        .get(this.apiUrl+"/dispositivo", this.configHeader)
        .then(response => {
            return response.data;
        })
        .then(res => {

          //console.log(res);

          this.items = res;

          this.items.forEach(item => {

            if(item.potencia == 0)
              item.potencia = 1;

            this.$mqtt.subscribe('radiant/'+item.url, {})
          });
        }).catch(error =>{

          if(error.response.data == "Token no válido"){
            this.userLogin = false;
          }
               
        });
    }

  },
   watch: {
    'itemSelect.ledValor': function() {
       if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        if(!this.cargandoIndividual && this.itemSelect.ledValor != -1)
        {
          this.publishLedDimable();      
        }

        if(this.cargandoIndividual)
          this.cargandoIndividual = false; 
          
      }, 100);

    },
  },
   mqtt: {
    'radiant/+' (data, topic) {
      let dataParse = String.fromCharCode.apply(null, data);

      this.items.forEach(item => {

        if(item.url == topic.split('/')[1])
        {
          switch (dataParse.split(':')[0]){
            case 'calefaccion':
                item.encendido = (dataParse.split(':')[1] === 'true');
              break;
            case 'potencia':
                item.potencia = parseInt(dataParse.split(':')[1]);
              break;
            case 'led':
                item.led = dataParse.split(':')[1] == "true";
              break;
            case 'dim':
                item.ledValor = (parseInt(dataParse.split(':')[1]) * 100)/220;
              break;

          }
          
        }
        
      });

      if(this.itemSelect.url == topic.split('/')[1])
      {
         switch (dataParse.split(':')[0]){
            case 'calefaccion':
                this.itemSelect.encendido = (dataParse.split(':')[1] === 'true');
              break;
            case 'potencia':
                this.itemSelect.potencia = parseInt(dataParse.split(':')[1]);
              break;
            case 'led':
              this.itemSelect.led = dataParse.split(':')[1] == "true";
              break;
            case 'dim':
              this.itemSelect.ledValor = (parseInt(dataParse.split(':')[1]) * 100)/220;
              break;

          }
      }
      this.$forceUpdate();

      //console.log(topic + ': ' + String.fromCharCode.apply(null, data))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}


.hello{
  background-color: #EDEAD3;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white; 
  color: #B9A16B;
  height: 70px;
  line-height: 70px; 
  align-items: center;
  justify-content: center;
}


.main-content-container {
  margin-bottom: 60px; /* Ajusta este valor según la altura del footer */
}

.footer-icon{
  font-size: 30px;
}

.card-round{
  border-radius: 13px;
}


.card-title
{
  text-align: left;
  margin-left: 15px;
  margin-top: 7px;
}

.icon-user{
  color: black;
}

.btn1-potencia{
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
}

.btn3-potencia{
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
}

.btn-group{
    border-color: #B9A16B;
    background-color: white;
    color: black;
    text-align: center;
    width: 33%;
}

.btn-activo{
  background-color: #B9A16B !important;
  border-color: #B9A16B !important;
  color: white !important;
}

.btn-encendido{
  border-color: #B9A16B !important;
}

.light {
  width: 100px;
  height: 100px;
  background-color: yellow; /* Color de la luz */
  transition: opacity 0.3s;
}

.boton-led{
  background-color: white !important;
  border-radius: 50%; box-shadow: 9px 7px 46px -16px rgba(0,0,0,0.75);
}

.div-back{
  flex: 0 0 auto;
  width: 8.33333333%;
  text-align: left;
}

.div-options{
  flex: 0 0 auto;
  width: 8.33333333%;
  text-align: right;
}

.div-nombre{
  flex: 0 0 auto;
    width: 83.33333333%;
}

 .img-control{
    max-width: 250px !important;
  }

@media (max-width: 310px) {
  .div-back{
    position: absolute;
    left: 0px;
  }

  .div-options{
    position: absolute;
    right: 15px;
  }

  .div-nombre{
    flex: 0 0 auto;
      width: 100%;
  }

  .div-potencia{
    margin-top: -20px;
  }

  .img-control{
    max-width: 200px !important;
  }

}

/*.range-container {
  position: relative;
  width: 100%;
  height: 10px; 
  background-color: #ccc; 
  border-radius: 5px;
}

.range-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #007bff; 
  border-radius: 5px;
}*/

.custom-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #B9A16B;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #B9A16B;
  cursor: pointer;
}


  /*input[type='range'] {
      -webkit-appearance: none;
      background-color: white;
    }
    
    input[type='range']::-webkit-slider-runnable-track {
      height: 30px;
      -webkit-appearance: none;
      color: #B9A16B;
      margin-top: -1px;
    }
    
    input[type='range']::-webkit-slider-thumb {
      width: 30px;
      -webkit-appearance: none;
      height: 30px;
      cursor: ew-resize;
      background: #434343;
      box-shadow: -50px 0 0 0px #B9A16B;
    }

    input[type="range"]::-moz-range-progress {
        background-color: #B9A16B; 
      }
      input[type="range"]::-moz-range-track {  
        background-color: white;
      }
   
      input[type="range"]::-ms-fill-lower {
        background-color: #B9A16B; 
      }
      input[type="range"]::-ms-fill-upper {  
        background-color: white;
      }*/

      .espejo-principal{
        max-width: 100px;
      }

      .marco-principal{
            max-width: 75px;
            padding: 5px;
      }

      .login-div{
        background-color: white;
      }
      .form-login{
        position: absolute;
        top: 50%;
      }


      .login-container {
            text-align: center;
        }

        .icon {
            font-size: 48px; /* Tamaño del icono */
            margin-bottom: 20px; /* Espacio entre el icono y el formulario */
        }

        .login-form {
            /*padding: 20px;*/
            max-width: 300px; /* Ancho máximo del formulario */
            text-align: center;
            margin: 0 auto; /* Centrar horizontalmente el formulario */
        }

        .form-group {
            margin: 10px 0;
        }

        .form-group input {
            width: 100%;
            padding: 10px;
            border-radius: 15%;
        }

        .form-group button {
            background-color: #007bff; /* Color de fondo del botón */
            color: white; /* Color de texto del botón */
            padding: 10px 20px;
            border: none;
            cursor: pointer;
        }

        .text-cuenta-nueva:hover{
          cursor: pointer;
        }

        .text-cuenta-nueva{
          text-decoration: underline; color: #B9A16B
        }

        .button-add{
          background-color: #B9A16B;
          border-color: #B9A16B;
          width: 41px;
        }

        .confirmacion .btn-secondary{
          position: absolute;
          left: 10px;
        }

        .btn-option:hover{
          cursor: pointer;
        }

        .btn-back:hover{
          cursor: pointer;
        }

        .dispositivo-on{
          color:#B9A16B;
          /*font-weight: bold;*/
        }

</style>