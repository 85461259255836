<template>
  <div id="app" class="container">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
   /*dheight: 800px;*/
}

html, body {
  margin: 0;
  padding: 0;
  width: 98%;
  /*height: 80%;*/
  background-color: #EDEAD3 !important;/* Cambia 'blue' al color que desees */
}

body {
  margin: 0;
  padding: 0;
  background-color: #EDEAD3;; /* Cambia 'blue' al color que desees */
}
</style>
