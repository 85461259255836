import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMqtt from 'vue-mqtt';

Vue.config.productionTip = false

Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const currentTimestamp = new Date().getTime();
const randomValue = Math.floor(Math.random() * 100000);
const clientId = 'App-' + currentTimestamp + '-' + randomValue;

//Vue.use(VueMqtt, 'wss://r80772a0.eu-central-1.emqx.cloud:8084/mqtt', { clientId: 'WebClient-' + parseInt(Math.random() * 100000), username: 'front', password: 'front' })
//Vue.use(VueMqtt, 'wss://u39346a3.eu-central-1.emqx.cloud:8084/mqtt', { clientId: 'WebClient-' + parseInt(Math.random() * 100000), username: 'front', password: 'front' })
Vue.use(VueMqtt, 'wss://x75961e1.ala.us-east-1.emqxsl.com:8084/mqtt', { clientId: clientId, username: 'j198sj1278sjzka912', password: 'eyz5pnyQHht5Ndf' })

new Vue({
    render: h => h(App),
}).$mount('#app')